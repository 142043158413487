import * as React from "react";
import { useStaticQuery, graphql } from "gatsby";
import SpeakersList from "../../components/speakers/speakersList";
import { Speaker } from "../../components/interfaces";
import { default as Spkrs } from "../../components/speakers";

interface SpeakersProps {
  speakers: Speaker[];
}

const Speakers: React.FC<SpeakersProps> = ({ speakers }) => {
  return (
    <div id="speakers">
      <div className="main">
        <Spkrs speakers={speakers} />
      </div>
    </div>
  );
};

export default Speakers;
