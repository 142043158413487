import * as React from "react";
import { Speaker } from "../interfaces";

interface SpeakerListItemProps {
  speaker: Speaker;
}

const SpeakerListItem: React.FC<SpeakerListItemProps> = ({ speaker }) => {
  const lastComma = speaker.title.lastIndexOf(", ");
  const title = speaker.title.substring(0, lastComma);
  const company = speaker.title.substring(lastComma +1);

  return (
    speaker?.disabledProfile != true && (
      <div className="speakerListItem">
        <img src={speaker.image} alt={speaker.name} title={speaker.name} />
        <div className="speakerInfo">
          <div className="speakerName">{speaker.name}</div>
          <div className="speakerTitle">
            {title != "" && <>{title},<br /></>}
            
            {company}
          </div>
        </div>
      </div>
    )
  );
};

export default SpeakerListItem;
