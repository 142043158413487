import * as React from "react";
import { Speaker } from "../interfaces";
import SpeakerListItem from "./speakerListItem";

interface SpeakersListProps {
  speakers: Speaker[];
}

const SpeakersList: React.FC<SpeakersListProps> = ({ speakers }) => {
  return (
    <div id="speakersList">
      {speakers &&
        speakers.map((speaker) => {
          return <SpeakerListItem key={speaker.name} speaker={speaker} />;
        })}
    </div>
  );
};

export default SpeakersList;
