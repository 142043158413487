import React from "react";
import SpeakersList from "../../components/speakers/speakersList";
import { Speaker } from "../../components/interfaces";

const Speakers = ({ speakers }) => {
  return (
    <div id="speakers">
      <h1>Speakers</h1>
      <SpeakersList speakers={speakers} />
      <br />
      <br />
      <div className="pending">* Pending Agency Approval</div>
    </div>
  );
};

export default Speakers;
